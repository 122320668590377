﻿for (var e = document.getElementsByTagName("iframe"), x = e.length; x--;) {
    if (/youtube.com\/embed/.test(e[x].src)) {
        if (e[x].src.indexOf('enablejsapi=') === -1) {
            e[x].src += (e[x].src.indexOf('?') === -1 ? '?' : '&') + 'enablejsapi=1';
        }
    }
}

var gtmYTListeners = [];
function onYouTubeIframeAPIReady() {
    for (var e = document.getElementsByTagName("iframe"), x = e.length; x--;) {
        if (/youtube.com\/embed/.test(e[x].src)) {
            gtmYTListeners.push(new YT.Player(e[x], {
                events: {
                    onStateChange: onPlayerStateChange,
                    onError: onPlayerError
                }
            }));
            YT.gtmLastAction = "p";
        }
    }
}

function onPlayerStateChange(e) {
    e["data"] == YT.PlayerState.PLAYING && setTimeout(onPlayerPercent, 1000, e["target"]);
    var video_data = e.target["getVideoData"](),
        label = video_data.video_id + ':' + video_data.title;
    if (e["data"] == YT.PlayerState.PLAYING && YT.gtmLastAction == "p") {
        VaneckGoogle.Tracking.Track('event', 'Youtube Player', 'Play', label);
        YT.gtmLastAction = "";
    }
    if (e["data"] == YT.PlayerState.PAUSED) {
        VaneckGoogle.Tracking.Track('event', 'Youtube Player', 'Pause', label);
        YT.gtmLastAction = "p";
    }
}

function onPlayerError(e) {
    VaneckGoogle.Tracking.Track('event', 'Youtube Player', 'Error', "youtube:" + e);
}

function onPlayerPercent(e) {
    if (e["getPlayerState"]() == YT.PlayerState.PLAYING) {
        var t = e["getDuration"]() - e["getCurrentTime"]() <= 1.5 ? 1 : (Math.floor(e["getCurrentTime"]() / e["getDuration"]() * 4) / 4).toFixed(2); if (!e["lastP"] || t > e["lastP"]) {
            var video_data = e["getVideoData"](),
                label = video_data.video_id + ':' + video_data.title;
            e["lastP"] = t;
            VaneckGoogle.Tracking.Track('event', 'Youtube Player', t * 100 + "%", label);
        }
        e["lastP"] != 1 && setTimeout(onPlayerPercent, 1000, e);
    }
}

window.onbeforeunload = function (e) {
    var e = e || window.event;
    if (e)
        e.returnValue = 'na';
    return 'na';
};
window.onbeforeunload = trackYTUnload;

function trackYTUnload() {
    try{
        for (var i = 0; i < gtmYTplayers.length; i++) {
            if (gtmYTlisteners[i].getPlayerState() === 1) { // playing
                var video_data = gtmYTlisteners[i]['getVideoData'](),
                label = video_data.video_id + ':' + video_data.title;
                VaneckGoogle.Tracking.Track('event', 'Youtube Player', 'exist', label);
            }
        }
    }
    catch (err)
    {

    }
}
var j = document.createElement("script"),
f = document.getElementsByTagName("script")[0];
j.src = "//www.youtube.com/iframe_api";
j.async = true;
f.parentNode.insertBefore(j, f);
